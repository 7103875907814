import React from "react";
import "./style.scss";

const Locale = ({ locale, setLocale }) => {
	const localeLabel = locale === "ru" ? "en" : "ru";

	const handleLocaleChange = () => {
		if (locale === "ru") {
			setLocale("en");
		} else {
			setLocale("ru");
		}

		// history.push(window.location.href.replace)
	};

	return (
		<div className="locale" onClick={handleLocaleChange}>
			{localeLabel.toUpperCase()}
		</div>
	);
};

export default Locale;
