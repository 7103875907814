import React from "react";
import { Image } from "cloudinary-react";
import { PHOTO_HEIGHT } from "./consts";

const Media = ({ content: { photos, video } }) => {
  return (
    <>
      {photos && (
        <>
          <div className="photo">
            <Image src={photos[0]} cloudName="hino-2" height={PHOTO_HEIGHT} />
          </div>
          <div className="photo">
            <Image src={photos[1]} cloudName="hino-2" height={PHOTO_HEIGHT} />
          </div>
        </>
      )}
      {video && (
        <div className="photo">
          <iframe
            height={PHOTO_HEIGHT * 2}
            src={video}
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
        </div>
      )}
    </>
  );
};

export default Media;
