import React from "react";

export const descs = [
  {
    locale: "ru",
    buttonLabel: "Мои проекты",
    desc: [
      <React.Fragment key={"homedesc"}>
        <p>
          Занимаюсь разработкой бэкенда на Node.js и фронтенда на React.
          <br />
        </p>
        <p>
          <a
            href="https://ekaterinburg.hh.ru/resume/1eb89c25ff0811e8dc0039ed1f466168416c62"
            target="_blank"
            rel="noopener noreferrer"
          >
            Резюме на hh.ru
          </a>
        </p>
        <p>
          <a
            href="https://www.linkedin.com/in/%D0%B8%D0%B3%D0%BE%D1%80%D1%8C-%D1%83%D1%88%D0%B0%D0%BA%D0%BE%D0%B2/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Профиль на LinkedIn
          </a>
        </p>
      </React.Fragment>,
    ],
  },
  {
    locale: "en",
    buttonLabel: "My projects",
    desc: [
      <React.Fragment key={"homedesc"}>
        <p>
          I develop backend with Node.js and frontend with React.
          <br />
        </p>
        <p>
          <a
            href="https://ekaterinburg.hh.ru/resume/b343c85cff0807bede0039ed1f3643465a3874"
            target="_blank"
            rel="noopener noreferrer"
          >
            CV at hh.ru
          </a>
        </p>
        <p>
          <a
            href="https://www.linkedin.com/in/%D0%B8%D0%B3%D0%BE%D1%80%D1%8C-%D1%83%D1%88%D0%B0%D0%BA%D0%BE%D0%B2/"
            target="_blank"
            rel="noopener noreferrer"
          >
            LinkedIn profile
          </a>
        </p>
      </React.Fragment>,
    ],
  },
];
