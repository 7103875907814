import React, { useEffect, useState } from "react";
import { Link, withRouter } from "react-router-dom";
import * as data from "./NavBarText.json";
import "./style.scss";

const NavBar = ({ location, locale }) => {
	useEffect(() => {
		const navBarDivs = document.querySelectorAll(".navbar div");
		if (navBarDivs) navBarDivs.forEach((node) => node.classList.remove("selected"));

		const selectedTab = document.querySelector(`div[pathname="${location.pathname}"]`);
		if (selectedTab) selectedTab.classList.add("selected");
	}, [location]);

	const [text, setText] = useState({
		about: "Обо мне",
		projects: "Проекты",
		contacts: "Контакты",
	});

	useEffect(() => {
		const { about, projects, contacts } = data.texts.find((item) => item.locale === locale);

		setText({
			about: about,
			projects: projects,
			contacts: contacts,
		});
	}, [locale]);

	return (
		<nav className="navbar">
			<div className="item" pathname={`/${locale}`}>
				<Link to={`/${locale}`}>{text.about}</Link>
			</div>
			<div className="item" pathname={`/${locale}/projects`}>
				<Link to={`/${locale}/projects`}>{text.projects}</Link>
			</div>
			<div className="item" pathname={`/${locale}/contacts`}>
				<Link to={`/${locale}/contacts`}>{text.contacts}</Link>
			</div>
		</nav>
	);
};

export default withRouter(NavBar);
