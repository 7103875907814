import React from "react";

export const projects = [
  {
    locale: "ru",
    projects: [
      {
        id: 1,
        name: "ценапосылки.рф",
        url: "https://ценапосылки.рф",
        github: [""],
        period: [new Date(2019, 11), new Date(2020, 2)],
        stack: ["jQuery", "PHP", "mysql"],
        photos: [
          "https://res.cloudinary.com/hino-2/image/upload/v1591095403/igor-ushakov/projects-photos/1-1.png",
          "https://res.cloudinary.com/hino-2/image/upload/v1591095404/igor-ushakov/projects-photos/1-2.png",
        ],
        desc: [
          <font key={`descfont1`}>
            Сервис, чтобы узнавать, сколько будет стоить отправка посылки куда
            угодно и когда она туда придет.
            <br />
            <br />В нем я связал несколько API:
            <br />
            <ul>
              <li>
                <b>API Почты России</b> - чтобы была возможность находить
                отделения почтовой связи по адресу или по координатам, получать
                информацию о них и рассчитывать цену и сроки пересылки между
                ними
              </li>
              <br />
              <li>
                <b>API Яндекс.Карт</b> - чтобы отображать ОПС на карте в виде
                меток, чтобы была возможность наглядно понимать откуда можно
                отправить посылку и куда она придет
              </li>
              <br />
              <li>
                <b>API DaData</b> - чтобы были подсказки при вводе адреса
              </li>
            </ul>
          </font>,
        ],
      },
      {
        id: 2,
        name: "Кот Зевс",
        url: "https://котзевс.рф",
        // github: ["https://github.com/hino-2/kotzevs"],
        github: [""],
        period: [new Date(2020, 2), new Date(2020, 2)],
        stack: ["React"],
        photos: [
          "https://res.cloudinary.com/hino-2/image/upload/v1591095404/igor-ushakov/projects-photos/2-1.png",
          "https://res.cloudinary.com/hino-2/image/upload/v1591095404/igor-ushakov/projects-photos/2-2.png",
        ],
        desc: [
          <font key={`descfont2`}>
            Ничего особенного в нем нет (кроме музыки!). Просто собрал несколько
            элементов и обернул их в React компоненты.
          </font>,
        ],
      },
      {
        id: 3,
        name: "priceofparcel-react",
        url: "https://priceofparcel-react.appspot.com/",
        github: [""],
        // github: [
        //   "https://github.com/hino-2/priceofparcel-react",
        //   "https://github.com/hino-2/priceofparcel-react-backend",
        // ],
        period: [new Date(2020, 3), new Date(2020, 4)],
        stack: ["React", "Redux", "Node.js", "Express.js"],
        photos: [
          "https://res.cloudinary.com/hino-2/image/upload/v1591095403/igor-ushakov/projects-photos/3-1.png",
          "https://res.cloudinary.com/hino-2/image/upload/v1591095404/igor-ushakov/projects-photos/3-2.png",
        ],
        desc: [
          <font key={`descfont3`}>
            Переписал проект "ценапосылки.рф" на стэке React/Node.js
            <br />
          </font>,
        ],
      },
      {
        id: 4,
        name: "Corona VI RUS Shop",
        url: "https://corona-v-shop.ew.r.appspot.com/",
        github: [""],
        // github: [
        //   "https://github.com/hino-2/corona-v-shop",
        //   "https://github.com/hino-2/corona-v-shop-backend",
        // ],
        period: [new Date(2020, 4), new Date(2020, 6)],
        stack: ["React", "Node.js", "Express.js", "MongoDB"],
        photos: [
          "https://res.cloudinary.com/hino-2/image/upload/v1591095404/igor-ushakov/projects-photos/4-1.png",
          "https://res.cloudinary.com/hino-2/image/upload/v1591095404/igor-ushakov/projects-photos/4-2.png",
        ],
        desc: [
          <font key={`descfont4`}>
            Этот интернет-магазин представляет из себя SPA. Переход между
            страницами с помощью React Router, а вместо Redux - useContext. Для
            оптимизации загрузки приложения используется react-lazyload и
            хранение всех изображений в CDN Cloudinary.
            <br />
            <br />
            Тесты PageSpeed Insights:
            <ul>
              <li>Для мобильных: 81/100</li>
              <li>Для настольных: 97/100</li>
            </ul>
            В магазине можно заказать доставку через виджет Почты России.
            Зарегистрированным пользователям при создании заказа приходит
            подтверждение по e-mail.
            <br />
            <br />
            Бэкенд написан на Express.js. Вся информация о товарах, заказах и
            пользователях хранится в MongoDB. Есть полноценная авторизация с
            помощью bcrypt.
          </font>,
        ],
      },
      {
        id: 5,
        name: "Hex grid",
        url: "https://hexgrid.ey.r.appspot.com/",
        github: [""],
        // github: ["https://github.com/hino-2/hexagonal-grid"],
        period: [new Date(2020, 6), new Date(2020, 6)],
        stack: ["JavaScript (canvas, service workers)", "React"],
        photos: [
          "https://res.cloudinary.com/hino-2/image/upload/v1593346642/igor-ushakov/projects-photos/5-1.png",
          "https://res.cloudinary.com/hino-2/image/upload/v1593346642/igor-ushakov/projects-photos/5-2.png",
        ],
        desc: [
          <font key={`descfont5`}>
            Это одно из тестовых заданий, которое я делал.
            <br />
            <br />
            Пользователь вводит 3 целочисленных размера гексагональной решетки:
            L, M и N. Они должны быть от 1 до 30. Решетку с указанными размерыми
            надо нарисовать.
            <br />
            <br />
            Каждой ячейке решетки может быть присвоено значение 0 или 1. Это
            можно сделать либо вручную кликом мышки по ячейке, либо
            автоматически с заданной вероятностью нажатием соответствующей
            кнопки.
            <br />
            <br />
            После этого нужно сосчитать количество получившихся одно- и
            неодносвязнных областей (доменов), закрасить эти области разными
            цветами и записать результат в таблицу.
            <br />
            <br />
            При большом количестве ячеек подсчет доменов занимает столько
            времени, что замирание UI начинает быть заметным. Чтобы этого не
            происходило, я перенес подсчет в отдельный тред с помощью service
            worker-а.
          </font>,
        ],
      },
      {
        id: 6,
        name: "ru-post-api",
        url: "https://www.npmjs.com/package/ru-post-api",
        github: [""],
        // github: ["https://github.com/hino-2/russian-post-api"],
        period: [new Date(2020, 6), new Date(2020, 6)],
        stack: ["JavaScript", "Node.js"],
        photos: [
          "https://res.cloudinary.com/hino-2/image/upload/v1593867951/igor-ushakov/projects-photos/6-1.png",
          "https://res.cloudinary.com/hino-2/image/upload/v1593867952/igor-ushakov/projects-photos/6-2.png",
        ],
        desc: [
          <font key={`descfont6`}>
            Библиотека для работы с API Почты России.
            <br />
            <br />
            Включает в себя 4 модуля для работы с API 4х микросервисов Почты:
            <ul>
              <li>
                <b>otpravka</b> - для работы с заказами на доставку и
                документами
              </li>
              <li>
                <b>tariff</b> - для расчета стоимости доставки
              </li>
              <li>
                <b>delivery</b> - для получения срока доставки
              </li>
              <li>
                <b>tracking</b> - для получения информации об отслеживании и
                наложенных платежах
              </li>
            </ul>
          </font>,
        ],
      },
      {
        id: 7,
        name: "Банк метаданных",
        url: "",
        github: [""],
        period: [new Date(2020, 7), new Date(2021, 11)],
        stack: [
          "TypeScript",
          "React",
          "Redux",
          "Saga",
          "Websocket",
          "Material-UI",
          "Node.js",
          "Express.js",
          "worker-thread",
          "MongoDB",
        ],
        photos: [
          "https://res.cloudinary.com/hino-2/image/upload/v1618753159/igor-ushakov/projects-photos/7-1.png",
          "https://res.cloudinary.com/hino-2/image/upload/v1618753159/igor-ushakov/projects-photos/7-2.png",
        ],
        desc: [
          <font key={`descfont7`}>
            Проект в Кари. <br />
            <br />
            Фронтенд/бэкенд внутреннего проекта по обработке и экспорту товаров,
            продаваемых через агрегаторы (Я.Маркет, Мегамаркет, Ozon, WB и
            т.д.).
            <br />
            <br />
            Создавал и оптимизировал процедуры обработки большого кол-ва данных
            (миллионы записей). Сократил время выполнения большинства из них в
            несколько раз, внедрив многопоточность. Развивал проект в команде с
            системным аналитиком, девопсом и фронтендером.
          </font>,
        ],
      },
      {
        id: 8,
        name: "Банк метаданных - мобильное приложение",
        url: "",
        github: [""],
        // github: ["https://github.com/hino-2/bim-mobile-public"],
        period: [new Date(2020, 2)],
        stack: ["TypeScript", "React Native", "Redux", "Saga", "Material-UI"],
        video: "https://www.youtube.com/embed/YdcbEd1wFo8",
        desc: [
          <font key={`descfont8`}>
            Фронтенд в виде мобильного приложения для Банка метаданных (см.
            ниже) <br />
            <br />
            В нем всего два экрана и те с обрезанной функциональностью. <br />
            <br />
            Сделал ради интереса, пока был в отпуске. <br />
            <br />
            Кое-что зацензурено.
          </font>,
        ],
      },
      {
        id: 9,
        name: "My Driving Academy",
        url: "https://mydrivingacademy.se",
        github: [""],
        period: [new Date(2021, 11), new Date()],
        stack: [
          "TypeScript",
          "Node.js",
          "Express.js",
          "InversifyJS",
          "MySQL",
          "AWS",
          "Firebase",
          "Docker",
        ],
        photos: [
          "https://res.cloudinary.com/hino-2/image/upload/v1647967672/igor-ushakov/projects-photos/9-1.png",
          "https://res.cloudinary.com/hino-2/image/upload/v1647967672/igor-ushakov/projects-photos/9-2.png",
        ],
        desc: [
          <font key="descfont9">
            Проект в Fastdev AB.
            <br />
            <br />
            Информационные системы для крупнейшей сети автошкол Швеции&nbsp;
            <a href="https://mydrivingacademy.se">
              https://mydrivingacademy.se
            </a>
            <br />
            <br />
            Состоит из монолита и нескольких микросервисов, связанных через AWS
            SQS.
            <br />
            <br />В составе команды из 7 человек сделал кастомную систему
            букинга. Полностью с нуля сделал подсистему покупок и постобработки
            заказов. Сделал несколько вспомогательных микросервисов и настроил
            CI/CD для них в AWS и Firebase. Под моим менторством у одной
            тестировщицы получилось стать фронтенд разработчиком, и двое джунов
            повысили свой грейд до middle.
          </font>,
        ],
      },
      {
        id: 10,
        name: "Shmenti",
        url: "http://shmenti.ru",
        github: ["https://github.com/hino-2/shmenti"],
        period: [new Date(2022, 3)],
        stack: [
          "TypeScript",
          "React",
          "AWS API Gateway",
          "AWS Lambda",
          "AWS DynamoDB",
          "AWS S3",
          "AWS EventBridge",
          "AWS CodePipeline",
          "AWS CodeBuild",
        ],
        photos: [
          "https://res.cloudinary.com/hino-2/image/upload/v1647966852/igor-ushakov/projects-photos/10-1.png",
          "https://res.cloudinary.com/hino-2/image/upload/v1647966852/igor-ushakov/projects-photos/10-2.png",
        ],
        desc: [
          <font key="descfont10">
            Один из моих проектов, который я написал, пока изучал AWS. Это
            серверлесс приложение на React, развернутое в облаке.
            <br />
            <br />С его помощью пользователи могут анонимно публиковать вопросы
            в рамках какой-нибудь сессии. Например, для общего митинга. Вопросы
            можно лайкать и отмечать как отвеченные. Все лайки и вопросы
            синхронизируются по вебсокету между всеми пользователями в одной
            сессии.
          </font>,
        ],
      },
      {
        id: 11,
        name: "Цена посылки V2",
        url: "https://ценапосылки.рф/",
        github: [],
        period: [new Date(2023, 10), new Date(2023, 11)],
        stack: [
          "TypeScript",
          "React",
          "ReduxToolkit",
          "MaterialUI",
          "Node.js",
          "NestJS",
          "Sequelize (MySQL)",
          "Bcrypt",
          "Google Auth Lib",
        ],
        photos: [
          "https://res.cloudinary.com/hino-2/image/upload/v1702892052/igor-ushakov/projects-photos/11-1.png",
          "https://res.cloudinary.com/hino-2/image/upload/v1647966852/igor-ushakov/projects-photos/11-2.png",
        ],
        desc: [
          <font key="descfont11">
            <p>
              Это "вторая версия" моего старого приложения. Она выглядит
              примерно точно так же, но под капотом все другое. Новая версия
              отличается от предыдущей следующими фичами:
            </p>
            <ul>
              <li>Полноценный бэкенд сервер на виртуальной машине в облаке</li>
              <li>
                HTTPS на бэкенде с самоподписанным сертификатом Let's Encrypt
              </li>
              <li>Авторизация через Google OAuth 2.0</li>
              <li>
                Монетизация через продажу подписок с помощью рекуррентных
                платежей Tinkoff Merchant API
              </li>
            </ul>
          </font>,
        ],
      },
    ],
  },
  {
    locale: "en",
    projects: [
      {
        id: 1,
        name: "Price of parcel",
        url: "https://ценапосылки.рф",
        github: [""],
        period: [new Date(2019, 11), new Date(2020, 2)],
        stack: ["jQuery", "PHP", "mysql"],
        photos: [
          "https://res.cloudinary.com/hino-2/image/upload/v1591095403/igor-ushakov/projects-photos/1-1.png",
          "https://res.cloudinary.com/hino-2/image/upload/v1591095404/igor-ushakov/projects-photos/1-2.png",
        ],
        desc: [
          <font key={`descfont1`}>
            A service to find out how much it will cost to send a package
            anywhere and when it will arrive there.
            <br />
            <br />I integrated several APIs into it:
            <br />
            <ul>
              <li>
                <b>Russian Post API</b> - to have an ability to find post
                offices by address or by coordinates, get info about these
                offices and calculate shipping cost/time between them
              </li>
              <br />
              <li>
                <b>Yandex Maps API</b> - to show post offices on the map as
                placemarks, so users could see where they have to bring their
                parcels and where to go to recieve them
              </li>
              <br />
              <li>
                <b>DaData API</b> - to have convenient suggestions for address
                input
              </li>
            </ul>
          </font>,
        ],
      },
      {
        id: 2,
        name: "Zeus the cat",
        url: "https://котзевс.рф",
        // github: ["https://github.com/hino-2/kotzevs"],
        github: [""],
        period: [new Date(2020, 2), new Date(2020, 2)],
        stack: ["React"],
        photos: [
          "https://res.cloudinary.com/hino-2/image/upload/v1591095404/igor-ushakov/projects-photos/2-1.png",
          "https://res.cloudinary.com/hino-2/image/upload/v1591095404/igor-ushakov/projects-photos/2-2.png",
        ],
        desc: [
          <font key={`descfont2`}>
            Nothing special (except for the music!). I took some elements from
            the internet and wrapped them into React components.
          </font>,
        ],
      },
      {
        id: 3,
        name: "priceofparcel-react",
        url: "https://priceofparcel-react.appspot.com/",
        github: [""],
        // github: [
        //   "https://github.com/hino-2/priceofparcel-react",
        //   "https://github.com/hino-2/priceofparcel-react-backend",
        // ],
        period: [new Date(2020, 3), new Date(2020, 4)],
        stack: ["React", "Redux", "Node.js", "Express.js"],
        photos: [
          "https://res.cloudinary.com/hino-2/image/upload/v1591095403/igor-ushakov/projects-photos/3-1.png",
          "https://res.cloudinary.com/hino-2/image/upload/v1591095404/igor-ushakov/projects-photos/3-2.png",
        ],
        desc: [
          <font key={`descfont3`}>
            Rewrote project "Price of parcel" on React/Node.js
            <br />
          </font>,
        ],
      },
      {
        id: 4,
        name: "Corona VI RUS Shop",
        url: "https://corona-v-shop.ew.r.appspot.com/",
        github: [""],
        // github: [
        //   "https://github.com/hino-2/corona-v-shop",
        //   "https://github.com/hino-2/corona-v-shop-backend",
        // ],
        period: [new Date(2020, 4), new Date(2020, 6)],
        stack: ["React", "Node.js", "Express.js", "MongoDB"],
        photos: [
          "https://res.cloudinary.com/hino-2/image/upload/v1591095404/igor-ushakov/projects-photos/4-1.png",
          "https://res.cloudinary.com/hino-2/image/upload/v1591095404/igor-ushakov/projects-photos/4-2.png",
        ],
        desc: [
          <font key={`descfont4`}>
            This online store is a SPA. Navigation between pages is done with
            React Router. I used useContext instead of Redux. To optimize
            application loading, react-lazyload is used and all images are
            stored in the Cloudinary CDN.
            <br />
            <br />
            PageSpeed Insights tests:
            <ul>
              <li>For mobile: 81/100</li>
              <li>For desktop: 97/100</li>
            </ul>
            You can order shipping with to Russian Post's widget. Registered
            users recieve order confirmation e-mails.
            <br />
            Backend is written using Express.js. All information about products,
            orders and users is stored in MongoDB. Authorization functionality
            is written using bcrypt.
          </font>,
        ],
      },
      {
        id: 5,
        name: "Hex grid",
        url: "https://hexgrid.ey.r.appspot.com/",
        github: [""],
        // github: ["https://github.com/hino-2/hexagonal-grid"],
        period: [new Date(2020, 6), new Date(2020, 6)],
        stack: ["JavaScript (canvas, service workers)", "React"],
        photos: [
          "https://res.cloudinary.com/hino-2/image/upload/v1593346642/igor-ushakov/projects-photos/5-1.png",
          "https://res.cloudinary.com/hino-2/image/upload/v1593346642/igor-ushakov/projects-photos/5-2.png",
        ],
        desc: [
          <font key={`descfont5`}>
            This is one of the test tasks which I did.
            <br />
            <br />
            User inputs 3 integer sizes for a hexagonal grid: L, M and N. They
            should be from 1 to 30. Grid with these sizes should be drawn.
            <br />
            <br />
            Every cell of the grid could be assigned with a value 0 or 1. This
            could be done manually by clicking on cell with the mouse or
            automatically with a certain possibility with a press of a "AUTO"
            button.
            <br />
            <br />
            After that I need to calculate the quantity of single- and
            multiconnected domains of selected cells, paint these domains in
            different colors and put results into a table.
            <br />
            <br />
            When number of cells is relatively large calculations start to
            noticably freeze the UI. In order to prevent this from happening I
            moved calculations into a separate thread using a service worker.
            While service worker is calculating buttons and canvas click are
            disabled.
          </font>,
        ],
      },
      {
        id: 6,
        name: "ru-post-api",
        url: "https://www.npmjs.com/package/ru-post-api",
        github: [""],
        // github: ["https://github.com/hino-2/russian-post-api"],
        period: [new Date(2020, 6), new Date(2020, 6)],
        stack: ["JavaScript", "Node.js"],
        photos: [
          "https://res.cloudinary.com/hino-2/image/upload/v1593867951/igor-ushakov/projects-photos/6-1.png",
          "https://res.cloudinary.com/hino-2/image/upload/v1593867952/igor-ushakov/projects-photos/6-2.png",
        ],
        desc: [
          <font key={`descfont6`}>
            Library which helps you use Russian Post APIs.
            <br />
            <br />
            It includes 4 modules for 4 different Post's microservices.
            <ul>
              <li>
                <b>otpravka</b> - for managing delivery orders and shipment
                documents
              </li>
              <li>
                <b>tariff</b> - for calculating delivery cost
              </li>
              <li>
                <b>delivery</b> - for calculating delivery period
              </li>
              <li>
                <b>tracking</b> - for getting tracking and C.O.D. info
              </li>
            </ul>
          </font>,
        ],
      },
      {
        id: 7,
        name: "Bank of metadata",
        url: "",
        github: [""],
        period: [new Date(2020, 7), new Date(2021, 11)],
        photo: "",
        stack: [
          "TypeScript",
          "React",
          "Redux",
          "Saga",
          "Websocket",
          "Material-UI",
          "Node.js",
          "Express.js",
          "worker-thread",
          "MongoDB",
        ],
        photos: [
          "https://res.cloudinary.com/hino-2/image/upload/v1618753159/igor-ushakov/projects-photos/7-1.png",
          "https://res.cloudinary.com/hino-2/image/upload/v1618753159/igor-ushakov/projects-photos/7-2.png",
        ],
        desc: [
          <font key={`descfont7`}>
            Project at Kari. <br />
            <br />
            Frontend/backend of an internal project for processing and exporting
            goods sold through aggregators (Я.Маркет, Мегамаркет, Ozon, WB,
            etc.).
            <br />
            <br />
            Created and optimized procedures for processing large amounts of
            data (millions of records). Reduced the execution time of most of
            them several times by introducing multithreading. Developed the
            project in a team with a systems analyst, devops and front-end
            developer.
            <br />
            <br />I can't provide access to the repositories or the website.
          </font>,
        ],
      },
      {
        id: 8,
        name: "Bank of metadata - mobile app",
        url: "",
        github: [""],
        // github: ["https://github.com/hino-2/bim-mobile-public"],
        period: [new Date(2020, 2)],
        stack: ["TypeScript", "React Native", "Redux", "Saga", "Material-UI"],
        video: "https://www.youtube.com/embed/YdcbEd1wFo8",
        desc: [
          <font key={`descfont8`}>
            Mobile App as a frontend for Bank of metadata
            <br />
            <br />
            It has only 2 screens with limited functionality.
            <br />
            <br />
            I made it for fun while on vacation.
            <br />
            <br />
            Some data is censored.
          </font>,
        ],
      },
      {
        id: 9,
        name: "My Driving Academy",
        url: "https://mydrivingacademy.se",
        github: [""],
        period: [new Date(2021, 11), new Date()],
        stack: [
          "TypeScript",
          "Node.js",
          "Express.js",
          "InversifyJS",
          "MySQL",
          "AWS SQS",
          "Firebase",
          "Docker",
        ],
        photos: [
          "https://res.cloudinary.com/hino-2/image/upload/v1647967672/igor-ushakov/projects-photos/9-1.png",
          "https://res.cloudinary.com/hino-2/image/upload/v1647967672/igor-ushakov/projects-photos/9-2.png",
        ],
        desc: [
          <font key="descfont9">
            Project at Fastdev AB.
            <br />
            <br />
            IT for the largest network of driving schools in Sweden&nbsp;
            <a href="https://mydrivingacademy.se">
              https://mydrivingacademy.se
            </a>
            <br />
            <br />
            It's a monolith with a couple of microservices attached to it.
            Communication is done via AWS SQS.
            <br />
            <br />
            As part of a team of 7 people, I created a custom booking system. I
            made a subsystem for purchasing and post-processing orders
            completely from scratch. Made several auxiliary microservices and
            set up CI/CD for them in AWS and Firebase. Under my mentorship, one
            tester became a front-end developer, and two juniors raised their
            grade to middle.
          </font>,
        ],
      },
      {
        id: 10,
        name: "Shmenti",
        url: "http://shmenti.ru",
        github: ["https://github.com/hino-2/shmenti"],
        period: [new Date(2022, 3)],
        stack: [
          "TypeScript",
          "React",
          "AWS API Gateway",
          "AWS Lambda",
          "AWS DynamoDB",
          "AWS S3",
          "AWS EventBridge",
          "AWS CodePipeline",
          "AWS CodeBuild",
        ],
        photos: [
          "https://res.cloudinary.com/hino-2/image/upload/v1647966852/igor-ushakov/projects-photos/10-1.png",
          "https://res.cloudinary.com/hino-2/image/upload/v1647966852/igor-ushakov/projects-photos/10-2.png",
        ],
        desc: [
          <font key="descfont10">
            One of my pet projects which I developed while studying AWS. It's a
            serverless React app deployed in the AWS cloud.
            <br />
            <br />
            With it users can anonymously post questions within some session.
            E.g. company meeting. Questions can be liked and marked as answered.
            Data is syncronized via websocket between all users connected to the
            same session.
          </font>,
        ],
      },
      {
        id: 11,
        name: "Price of parcel V2",
        url: "https://ценапосылки.рф/",
        github: [],
        period: [new Date(2023, 10), new Date(2023, 11)],
        stack: [
          "TypeScript",
          "React",
          "ReduxToolkit",
          "MaterialUI",
          "Node.js",
          "NestJS",
          "Sequelize (MySQL)",
          "Bcrypt",
          "Google Auth Lib",
        ],
        photos: [
          "https://res.cloudinary.com/hino-2/image/upload/v1702892052/igor-ushakov/projects-photos/11-1.png",
          "https://res.cloudinary.com/hino-2/image/upload/v1647966852/igor-ushakov/projects-photos/11-2.png",
        ],
        desc: [
          <font key="descfont11">
            <p>
              This is the "second version" of my old app. It looks pretty much
              exactly the same, but under the hood it's completely different.
              This version differs from the previous one in such features:
            </p>
            <ul>
              <li>Dedicated backend on a virtual server in the cloud</li>
              <li>
                HTTPS on the backend with a self-signed Let's Encrypt
                certificate
              </li>
              <li>Authorization with Google OAuth 2.0</li>
              <li>
                Monetization through selling subscription with Tinkoff Merchant
                API's recurrent payments
              </li>
            </ul>
          </font>,
        ],
      },
    ],
  },
];
