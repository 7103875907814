/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useCallback, useMemo } from "react";
import { useHistory } from "react-router-dom";
import LazyLoad from "react-lazyload";
import { PHOTO_HEIGHT, months } from "./consts";
import Media from "./media";
import "./style.scss";

const Project = ({
  id,
  name,
  url,
  github,
  period,
  stack,
  photos,
  video,
  desc,
  locale,
}) => {
  const history = useHistory();

  const periodString = useMemo(
    () =>
      period
        .map(
          (period) =>
            `${
              months.find((item) => item.locale === locale).names[
                period.getMonth()
              ]
            } ${period.getFullYear()}`
        )
        .filter((item, i, arr) => arr.indexOf(item) === i)
        .join(" - "),
    [locale, period]
  );

  const githubString = useMemo(
    () =>
      github.map((repo, i) => (
        <React.Fragment key={`gth${i}-${id}`}>
          <a href={repo} target="_blank" rel="noopener noreferrer">
            {repo}
          </a>
          <br />
        </React.Fragment>
      )),
    [github, id]
  );

  const handleClick = useCallback(
    (id) => () => {
      if (id === 1) {
        history.push(`/${locale}/contacts`);
        return;
      }

      document
        .querySelector(`#littleoffset${id - 1}-1`)
        .scrollIntoView({ behavior: "smooth", block: "start" });
    },
    [history, locale]
  );

  return (
    <LazyLoad
      offset={300}
      height={PHOTO_HEIGHT * 2 + 200}
      key={`project${id}-1`}
    >
      <div className="project" id={`project${id}-1`}>
        <div className="about">
          <div>
            <div style={{ position: "relative" }}>
              <a
                id={`littleoffset${id}-1`}
                style={{ position: "absolute", top: "-50px" }}
              />
            </div>
            <div className="name">
              <h2>{`#${id} ${name}`}</h2>
            </div>
            <div className="stack">{stack.join(" / ")}</div>
            {/* <div className="period">{periodString}</div> */}
            <div className="desc">{desc}</div>
            <div className="github">{githubString}</div>
          </div>
          <div>
            <div className="check-it-out">
              {url && (
                <a href={url} target="_blank" rel="noopener noreferrer">
                  <b>{locale === "ru" ? "Посмотреть" : "Take a look"}</b>
                </a>
              )}
            </div>
            <div className="button">
              {id === 1 ? (
                <div className="to-top">
                  <a
                    href=""
                    onClick={(e) => {
                      e.preventDefault();
                      window.scrollTo(0, 0);
                    }}
                  >
                    {locale === "ru" ? "Вверх" : "Up"}
                  </a>
                </div>
              ) : (
                []
              )}
              <button className="btn" onClick={handleClick(id)}>
                {id === 1
                  ? locale === "ru"
                    ? "Контакты"
                    : "Contacts"
                  : locale === "ru"
                  ? "Предыдущий"
                  : "Previous"}
              </button>
            </div>
          </div>
        </div>
        <Media content={{ photos, video }} />
      </div>
    </LazyLoad>
  );
};
export default Project;
