import React from "react";
import { Switch, Route, withRouter } from "react-router-dom";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import Home from "../Home";
import Projects from "../Projects";
import Contacts from "../Contacts";
import "./style.scss";

const Container = ({ location }) => (
	<div className="container">
		<TransitionGroup className="transition-group">
			<CSSTransition key={location.pathname} timeout={{ enter: 300, exit: 300 }} classNames="fade">
				<section className="route-section">
					<Switch location={location}>
						<Route path="/:locale" exact component={Home} />
						<Route path="/:locale/projects" component={Projects} />
						<Route path="/:locale/contacts" component={Contacts} />
					</Switch>
				</section>
			</CSSTransition>
		</TransitionGroup>
	</div>
);

export default withRouter(Container);
