import React, { useEffect } from "react";
import "./style.scss";

const PERSONAL_EMAIL = 'i.am@igor-ushakov.ru'

const Contacts = ({
	match: {
		params: { locale },
	},
}) => {
	useEffect(() => {
		return () => window.scrollTo(0, 0);
	});

	return (
		<div className="contacts">
			<div className="icon">
				<img src="map-marker-alt-solid.svg" width="35px" height="35px" alt="location" />
			</div>
			<div className="city">
				<a
					href={
						locale === "ru"
							? "https://www.google.com/search?q=%D0%B1%D0%B8%D0%BB%D0%B5%D1%82%D1%8B+%D0%B2+%D0%B5%D0%BA%D0%B0%D1%82%D0%B5%D1%80%D0%B8%D0%BD%D0%B1%D1%83%D1%80%D0%B3&ie=UTF-8"
							: "https://www.google.com/search?q=tickets+to+yekaterinburg"
					}
					target="_blank"
					rel="noopener noreferrer">
					{locale === "ru" ? "Екатеринбург" : "Yekaterinburg"}
				</a>
			</div>
			<div className="icon">
				<img src="mobile-alt-solid.svg" width="35px" height="35px" alt="phone" />
			</div>
			<div className="call">
				<a href="tel:+7 922 149 37 38">+7 922 149 37 38</a>
			</div>
			<div className="icon">
				<img src="telegram-brands.svg" width="35px" height="35px" alt="tg" />
			</div>
			<div className="telegram">
				<a href="tg://resolve?domain=hino_2">@hino_2</a>
			</div>
			<div className="icon">
				<img src="envelope-solid.svg" width="35px" height="35px" alt="email" />
			</div>
			<div className="email">
				<a
					href={
						locale === "ru"
							? `mailto:${PERSONAL_EMAIL}?subject=Работа`
							: `mailto:${PERSONAL_EMAIL}?subject=Job`
					}>
					{PERSONAL_EMAIL}
				</a>
			</div>
		</div>
	);
};

export default Contacts;
