import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Image, Transformation } from "cloudinary-react";
import { descs } from "./HomeText";
import "./style.scss";

const Home = ({
	match: {
		params: { locale },
	},
}) => {
	const [text, setText] = useState({
		buttonLabel: "Мои проекты",
		desc: "",
	});

	useEffect(() => {
		return () => window.scrollTo(0, 0);
	});

	useEffect(() => {
		const { buttonLabel, desc } = descs.find((item) => item.locale === locale);
		setText({
			buttonLabel: buttonLabel,
			desc: desc,
		});
	}, [locale]);

	return (
		<div className="home">
			<div className="photo">
				<Image cloudName="hino-2" publicId={`v1/igor-ushakov/selfie.jpg`}>
					<Transformation gravity="east" height="300" width="300" radius="max" x="0" crop="fill" />
				</Image>
			</div>
			<div className="desc">{text.desc}</div>
			<div className="button">
				<Link to={`/${locale}/projects`}>
					<button>{text.buttonLabel}</button>
				</Link>
			</div>
		</div>
	);
};

export default Home;
