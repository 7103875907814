import React, { useState, useEffect } from "react";
// import { BrowserRouter } from "react-router-dom";
import { HashRouter } from "react-router-dom";
import Locale from "../Locale";
import NavBar from "../NavBar";
import Container from "../Container";
import "./App.scss";

function App() {
	const [locale, setLocale] = useState("ru");

	useEffect(() => {
		if (!window.location.href.match(/\/ru/) && !window.location.href.match(/\/en/))
			window.location.href = window.location.href + "ru";
	}, []);

	useEffect(() => {
		window.location.href =
			locale === "en" ? window.location.href.replace("/ru", "/en") : window.location.href.replace("/en", "/ru");

		document.title = locale === "ru" ? "Ушаков Игорь" : "Igor Ushakov";
	}, [locale]);

	return (
		<HashRouter>
			<div className="App">
				<Locale locale={locale} setLocale={setLocale} />
				<NavBar locale={locale} />
				<Container locale={locale} />
			</div>
		</HashRouter>
	);
}

export default App;
