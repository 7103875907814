import React from "react";
import Project from "../Project";
import { projects } from "./projects";
import "./style.scss";

const Projects = ({
	match: {
		params: { locale },
	},
}) => (
	<div className="projects">
		{projects
			.find((item) => item.locale === locale)
			.projects.sort((pr1, pr2) => pr2.id - pr1.id)
			.map((project) => (
				<Project {...project} locale={locale} key={`project${project.id}`} />
			))}
	</div>
);
export default Projects;
